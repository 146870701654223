import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { saveAs } from 'file-saver';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  mail: string = ''

  constructor(private api: ApiService) { }

  ngOnInit() {
  }

  downloadFile(){
    if(this.mail != ''){
      this.api.getProductGroups(this.mail).subscribe(
        (res) => {
          window.open('https://fhouse-public.s3.amazonaws.com/FH+Capability+Statement.pdf', '_blanc')
        },
        (error) => {
          window.open('https://fhouse-public.s3.amazonaws.com/FH+Capability+Statement.pdf', '_blanc')
        }
      );
    }

  }

}
