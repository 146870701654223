<section class="pt-4 pb-0">
	<div class="container">
		<article>
			<h2>SMS Terms and Conditions</h2>
			<p>Effective date: March 1, 2023</p>
      <p>By consenting to receive text message from or on behalf of Freight House Inc to the cellular phone number you provide to us, you agree to these Terms and Conditions and Privacy Policy. By giving your consent, you are confirming you are at least 18 years old.</p>
      <p>
        Depending on your relationship with us and consents received, Freight House Inc may send short message service, multimedia message service or other similar text messages (collectively, text messages), for transactional and/or marketing purposes. These text messages are used to: (1) provide you with requested information; (2) provide updates on freight movement; (3) resolve concerns and issues related to your transactions; (4) communicate about credit collection; (5) send advertising or marketing materials. Message frequency may vary.
      </p>
      <p>
        You may provide Freight House Inc with consent to send you text messages through communications or transactions with us (e.g. by initiating or agreeing to receive text message communications with our agents). Consent to receive text messages is not required as a condition of purchasing any goods or services. You may opt-out of these communications at any time by emailing
        <a href = "mailto: SMS@freighthousecorp.com">SMS@freighthousecorp.com</a> or the agent assigned to your account.
      </p>
      <p>
        Freight House Inc does not have a separate charge for this service; however message and data rates may apply from your mobile carrier.
      </p>
      <p>
        Freight House Inc may modify these Terms from time to time with or without notice to you. Unless otherwise indicated, modifications will be effective on the date they are posted on this page or any successor page and supersede prior Terms.
      </p>
      <p>
        Please notify Freight House Inc immediately if you change your mobile number. We are not liable for any communication or transmission of information by text which happens because you did not report changes to your mobile number.
      </p>
    </article>
	</div>
</section>
