<section class="pt-4 pb-0">
	<div class="container">
		<article>
			<h2>Contact</h2>
			<p>Nenad (Ned) Radosavljevic – President<br>
				<a href="tel:(872)250-3400">(872)250-3400</a><br>
				<a href="mailto:ned@freighthousecorp.com">ned@freighthousecorp.com</a><br>
        720 Plainfield Rd Ste 105B <br>
        Willowbrook, IL, 60527

      </p>
		</article>
	</div>
</section>
