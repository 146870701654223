<section class="pt-4 pb-0">
	<div class="container">
		<article>
			<h2>SMS Messaging Privacy Policy</h2>
			<p>Effective date: March 1, 2023</p>
      <p>This SMS Messaging Privacy Policy (“Policy”) governs how we collect and use information about you in relation to Freight House Inc. text message program.</p>
      <p>By consenting to receive text message from or on behalf of Freight House Inc, you agree to the terms of this Policy. Freight House Inc reserves the right, in our sole discretion, to modify or change this Policy at any time with or without prior notice to you. The date of the last update will be posted at the top of this Policy for your convenience. This Policy, and any changes, are effective as soon as posted and supersede any prior Policies. Your continued use of the SMS Messaging with Freight House Inc following the posting of any changes to the Policy constitutes your full acceptance of those changes.</p>
      <b>Collection of Information</b>
      <p>Through your use of the SMS Messaging Service, we will receive Personal Information through our third-party service provider. “Personal Information” is information that individually identifies you, such as your mobile phone number you provided when signing up for the Messaging Service, any user or screen name that you select in connection with the Messaging Service, any comments or feedback regarding the Messaging Service that you send to us, or any other information that you choose to include in messages you send through the Messaging Service. When you send messages via the Messaging Service, we will also collect your messaging history and any information included in those messages.</p>
      <p>We may also collect Personal Information about you using cookies or similar technologies. Cookies are pieces of information that are stored by your browser on the hard drive or memory of your device. Cookies enable personalization of your experience on the Messaging Service (e.g., sending you personalized text messages such as shopping cart reminders).</p>
      <p>If you participate in a contest, sweepstakes, research study, or email survey associated with the Messaging Service, we will collect basic contact information and any other information you choose to provide in connection with these activities. We will also collect your contact information if you contact us with questions about the Messaging Service or for customer service.</p>
      <b>Use of Information</b>
      <p>We use Personal Information to deliver, analyze, maintain and support the Messaging Service. We may also use Personal Information to enhance the Messaging Service features and customize and personalize your experiences on the Messaging Service.</p>
      <b>Sharing of Information</b>
      <p>Freight House Inc will not rent or sell your Personal Information to other companies or individuals unless we have your consent. We may use or disclose Personal Information in any of the following limited circumstances:</p>
      <ul>
        <li>We have your consent.</li>
        <li>We need to enforce our Terms of Service.</li>
        <li>We provide such information to trusted businesses or persons for the sole purpose of processing Personal Information on our behalf or providing the Messaging Service to you. When this is done, it is subject to agreements that oblige those parties to process such information only on our instructions and in compliance with this Policy and appropriate confidentiality and security measures. If the third party fails to comply with our terms, Freight House Inc is not accountable in any way for any liability or reimbursement.</li>
        <li>We believe disclosure of Personal Information is necessary or appropriate to: (i) comply with applicable law and legal processes; (ii) respond to requests from public and government authorities, including public and government authorities outside your country of residence; (iii) enforce a contract with us; (iv) protect our rights, privacy, safety, or property, and/or that of our affiliates, you or others; and (v) allow us to pursue available remedies or limit the damages that we may sustain.</li>
      </ul>
      <b>Protection of Information</b>
      <p>Freight House Inc takes a variety of physical, technical, administrative, and organizational security measures based on the sensitivity of the information we collect to protect your Personal Information against accidental or unlawful destruction or accidental loss, alteration, unauthorized disclosure or access. Unfortunately, no online activity can be guaranteed to be 100% secure. You should note that in using the Messaging Service, your information will travel through third-party infrastructures which are not under our control (such as a third-party provider’s SMS delivery platform or your carrier network). While we strive to protect your information against unauthorized use or disclosure, we cannot ensure or warrant the security of any information you provide. By using the Messaging Service, you agree that Freight House Inc is not liable for any unintentional disclosure.</p>
      <b>Children and Intended Audience</b>
      <p>The Messaging Service is not intended for children under 18, and Freight House Inc does not knowingly collect information from children under the age of 18. By using the Messaging Service,
        you are representing that you are at least 18, or that you are at least 13 years old and have your parents permission to use the service.</p>
      <b>Retention of Information</b>
      <p>We retain your Personal Information for as long as you participate in the Messaging Service or as needed to comply with applicable legal obligations. We will also retain and use your Personal Information as necessary to resolve disputes, protect us and our customers, and enforce our agreements.</p>
      <b>Choices and Controls</b>
      <p>
        Consent to receive automated marketing text messages is not a condition of any purchase. You can opt-out of receiving further commercial text messages from Freight House Inc by sending an email to
        <a href = "mailto: SMS@freighthousecorp.com">SMS@freighthousecorp.com</a>
         or to the agent aside to your account.
      </p>
    </article>
	</div>
</section>
